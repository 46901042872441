
import { Vue, Component } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';

@Component({
  components: {
    BaseButton,
  },
})
export default class Integrations extends Vue {
  imgName(index: number): string {
    const imgNumber = index + 1;
    if (imgNumber >= 23) return `${imgNumber}.png`;
    return `${imgNumber}.svg`;
  }

  goToIntegrationsPage(): void {
    this.$router.push({ name: 'IntegrationsPage' });
  }
}
